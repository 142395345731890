import React, {
  useCallback, useEffect, useState, SyntheticEvent,
} from 'react';
import Dialog from '@reach/dialog';
import {
 Checkboxes, Details, Tag, Select, Button,
} from 'govuk-react-jsx';
import { getDataSources, DataSource } from '../api/data_sources';
import { triggerCommissionConfigPull } from '../api/commissions';
import RefreshButton from '../components/RefreshButton';
import StatusAccordion from './StatusAccordion';
import InternalError from '../pages/InternalError';
import ReturnStat from './components/ReturnStat';
import { Sort, SortableOptions } from './sortTypes';


type Filters = {
  commission: boolean,
  api: boolean,
  today: boolean,
};

const commissionConfigActionHandler = (
  setCommissionConfigMessage: React.Dispatch<React.SetStateAction<Error | string>>,
) => async () => {
  try {
    const triggerResult = await triggerCommissionConfigPull()
    setCommissionConfigMessage(triggerResult.message)
  } catch (e) {
    setCommissionConfigMessage(e);
  }
};

const dataSourceRefresher = (
  setDataSources: React.Dispatch<React.SetStateAction<Error | DataSource[] | undefined>>,
) => async (abortSignal?: AbortSignal) => {
  try {
    const data = await getDataSources(abortSignal)
    setDataSources([...data]); // cloning object to force re-render of components
  } catch (e) {
    setDataSources(e);
  }
};

const countByStatus = (
  dataSources: DataSource[], status: string,
): number => dataSources.filter((ds) => {
  if (ds.status && ds.status.length > 0) {
    const latest = ds.status[ds.status.length - 1];
    if (latest.updates && latest.updates.length > 0) {
      // We have a latest update, get its status
      if (latest.updates[latest.updates.length - 1].status === status) {
        return true;
      }
    }
  }
  return false;
}).length;

const countWithAcquisitions = (
  dataSources: DataSource[],
): number => dataSources.filter((ds) => ds.status && ds.status.length > 0).length;

const countRequested = (
  dataSources: DataSource[],
): number => dataSources.filter((ds) => {
  if (ds.status && ds.status.length > 0) {
    const latest = ds.status[ds.status.length - 1];
    if (latest.updates && latest.updates.length === 0) {
      return true;
    }
  }
  return false;
}).length;

const filtersToCheckboxes = (filters: Filters): Object[] => [
  {
    checked: filters.commission,
    children: 'Commissions',
    hint: {
      children: 'Include data sources of type commission',
    },
    id: 'item_commissions',
    name: 'commission',
    value: 'commission',
  },
  {
    checked: filters.api,
    children: 'APIs',
    hint: {
      children: 'Include data sources of type API',
    },
    id: 'item_api',
    name: 'api',
    value: 'api',
  },
  {
    checked: filters.today,
    children: 'Today only',
    hint: {
      children: 'Only show data sources that have been updated today',
    },
    name: 'today',
    value: 'today',
  },
];

const filtersUpdate = (current: Filters, changed: string): Filters => {
  const newFilters: Filters = { ...current };
  switch (changed) {
    case 'commission':
      newFilters.commission = !newFilters.commission;
      break;
    case 'api':
      newFilters.api = !newFilters.api;
      break;
    case 'today':
      newFilters.today = !newFilters.today;
      break;
  }
  return newFilters
}

export default (
  { isAdmin }:
    { isAdmin: boolean },
) => {
  const [dataSources, setDataSources] = useState<DataSource[] | Error>();
  const refresh = useCallback(dataSourceRefresher(setDataSources), [setDataSources]);
  const [filters, setFilters] = useState<Filters>({ commission: true, api: true, today: false });
  const [filteredDataSources, setFilteredDataSources] = useState<DataSource[]>();
  const [commissionConfigDialogOpen, setCommissionConfigDialogOpen] = useState<boolean>(false);
  const [commissionConfigMessage, setCommissionConfigMessage] = useState<Error | string>('');
  const refreshCommissionConfig = useCallback(
    commissionConfigActionHandler(setCommissionConfigMessage),
    [setCommissionConfigMessage],
  );
  const [sort, setSort] = useState<Sort>({
    sortBy: SortableOptions.department,
  });

  function sortUpdated(a: DataSource, b: DataSource) {
    const sortField = sort.sortBy
    if (sortField === SortableOptions.date) {
      // complexity in this derrives from handling comissions with no status.
      let maxDate1
      let maxDate2
      if (a.status && a.status.length > 0) {
        maxDate1 = Math.max.apply(null, a.status!.map((s) => s.acquisition_id))
      } else {
        return 1
      }

      if (b.status && b.status.length > 0) {
        maxDate2 = Math.max.apply(null, b.status!.map((s) => s.acquisition_id))
      } else {
        return -1
      }

      if (maxDate1 === maxDate2) return 0

      return maxDate1 > maxDate2 ? -1 : 1
    }
    // do not do any sorting if not date.
    return 0
  }

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    // If the filters or the dataSources change, re-do the filtering that drives the display
    if (!dataSources || dataSources instanceof Error) {
      setFilteredDataSources([]);
    } else {
      // Compute the date at midnight this morning... used for the "today" filter
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      // Filter out data sources that do not match the filter settings
      const updated = dataSources.filter((ds) => {
        if (!filters.commission && ds.type === 'commission') {
          return false;
        }
        if (!filters.api && ds.type === 'api') {
          return false;
        }
        if (filters.today) {
          if (!ds.status || ds.status.length === 0) {
            // No updates, definitely not changed today!
            return false;
          }
          const time = new Date(ds.status[ds.status.length - 1].acquisition_id / 1000);
          if (time < today) {
            return false;
          }
        }
        return true;
      });

      if (sort.sortBy === SortableOptions.date) {
        const sortedUpdated = updated.sort(sortUpdated)
        setFilteredDataSources(sortedUpdated);
      } else {
        setFilteredDataSources(updated);
      }
    }
  }, [filters, dataSources, sort]);

  if (!dataSources) {
    return <p className="govuk-caption-m">Loading…</p>;
  }
  if (dataSources instanceof Error) {
    return <InternalError errorMessage={dataSources.message} />
  }

  return (
    <>
      <div className="govuk-grid-row">
        <h1 className="govuk-heading-l govuk-grid-column-three-quarters-from-desktop">Data Sources</h1>
        <div className="govuk-grid-column-one-quarter-from-desktop govuk-table__cell--numeric">
          {dataSources && <RefreshButton action={() => refresh()} />}
        </div>
      </div>
      <Details summaryChildren="Filter">
        <Checkboxes
          className="govuk-checkboxes--small"
          items={filtersToCheckboxes(filters)}
          name="filters"
          onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
            setFilters(filtersUpdate(filters, e.currentTarget.value));
          }}
        />
      </Details>
      <Details summaryChildren="Sort">
        <Select
          items={[{
            // checked: filters.commission,
            children: 'Department',
            value: SortableOptions.department,
          }, {
            children: 'Date',
            value: SortableOptions.date,
          }]}
          onChange={(e:any) => {
            setSort({ ...sort, sortBy: e.currentTarget.value })
          }}
          value={sort.sortBy}
          name=""
          label=""
        />
        {' '}

      </Details>
      {(!filteredDataSources || filteredDataSources.length === 0)
        ? <p className="govuk-caption-m">No data sources…</p>
        : (
          <>
            <div className="govuk-grid-row govuk-!-margin-bottom-5">
              <div className="govuk-grid-column-one-third-from-desktop govuk-!-margin-bottom-2">
                <ReturnStat
                  name={<Tag className="govuk-tag--green govuk-!-font-size-24">Success</Tag>}
                  current={countByStatus(filteredDataSources, 'success')}
                  total={countWithAcquisitions(filteredDataSources)}
                />
              </div>
              <div className="govuk-grid-column-one-third-from-desktop govuk-!-margin-bottom-2">
                <ReturnStat
                  name={<Tag className="govuk-tag--red govuk-!-font-size-24">Failure</Tag>}
                  current={countByStatus(filteredDataSources, 'failure')}
                />
              </div>
              <div className="govuk-grid-column-one-third-from-desktop govuk-!-margin-bottom-2">
                <ReturnStat
                  name={<Tag className="govuk-tag--yellow govuk-!-font-size-24">Requested</Tag>}
                  current={countRequested(filteredDataSources)}
                />
              </div>
            </div>
            <StatusAccordion
              dataSources={filteredDataSources}
              sort={sort}
            />
          </>
        )}
      <br />

      {isAdmin && (
        <span>
          <Button
            type="submit"
            onClick={() => {
              refreshCommissionConfig()
              setCommissionConfigDialogOpen(true)
            }}
          >
            Refresh Commission Configuration Files
          </Button>
          {commissionConfigMessage instanceof Error
        ? (
          <Dialog
            isOpen={commissionConfigDialogOpen}
            onDismiss={() => {
              setCommissionConfigMessage('');
              setCommissionConfigDialogOpen(false);
            }}
          >
            <div className="govuk-grid-row">
              <InternalError errorMessage={commissionConfigMessage.message} />
              <Button
                type="button"
                onClick={() => {
                  setCommissionConfigMessage('');
                    setCommissionConfigDialogOpen(false);
                }}
                style={{ float: 'right' }}
              >
                Close
              </Button>
            </div>
          </Dialog>
        )
        : (
          <Dialog
            isOpen={commissionConfigDialogOpen}
            onDismiss={() => {
              setCommissionConfigMessage('');
              setCommissionConfigDialogOpen(false);
            }}
          >
            <div className="govuk-grid-row">
              <h1 className="govuk-heading-l">
                Refreshing Commission Configuration Files
              </h1>
              <p className="govuk-body">
                {commissionConfigMessage.length === 0 ? 'Loading...' : commissionConfigMessage}
              </p>
              <Button
                type="button"
                onClick={() => {
                    setCommissionConfigMessage('');
                    setCommissionConfigDialogOpen(false);
                }}
                style={{ float: 'right' }}
              >
                Close
              </Button>
            </div>
          </Dialog>
        )}
        </span>
      )}
    </>
  );
};
