import React, { ReactNode } from 'react';
import { dayDescription, timeDescription } from '../api/dates';

/**
 * Show date and optionally time, formatted short.
 * @param date to display
 * @param showTime after a delimiter
 * @param delimiter between date and time
 */
export default (
  { date, showTime, delimiter }:
    { date: Date, showTime?: boolean, delimiter?: ReactNode },
) => (
  <>
    {dayDescription(date)}
    {showTime && (
      <>
        {delimiter ?? <br />}
        {timeDescription(date)}
      </>
    )}
  </>
);
