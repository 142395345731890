/* eslint-disable react/jsx-indent */
import React from 'react';

import { Accordion } from 'govuk-react-jsx';

import { Metric } from '../api/data_sources';
import { AccordionItem } from '../components/govukTypes';
import Schedule from './Schedule';

function listGeneration(name: string, items: string[]): JSX.Element {
  return (
    <>
      <h3 className="govuk-heading-m">{name}</h3>
      <ul className="govuk-body">
        {items.map((i) => <li key={i}>{i}</li>)}
      </ul>
    </>
  )
}

function accordionItems2(metrics: Metric[]): AccordionItem[] {
  return metrics.map((metric) => ({
    heading:
    {
      children:
        <>
          <h3 className="govuk-heading-m govuk-!-margin-bottom-0">
            {metric.name}
            {' '}
            {metric.cut && metric.cut.length > 0 ? `(${metric.cut.length} cut${metric.cut.length !== 1 ? 's' : ''})` : ''}
          </h3>
        </>,
    },
    summary: {
      children:
        <>
          <span className="govuk-caption-m govuk-!-font-weight-bold">{metric.id}</span>
        </>,
    },
    content: {
      children:
        <>
          <Schedule cron={metric.schedule} />
          <br />
          {metric.history_to_request && metric.history_to_request.length > 0
            && <p className="govuk-body">{`Additional days history to be requested: ${metric.history_to_request}`}</p>}
          {metric.feed_type
            && <p className="govuk-body">{`Feed type: ${metric.feed_type}`}</p>}
          {metric.cut && metric.cut.length > 0
            ? listGeneration('Cuts:', metric.cut)
            : <span className="govuk-body">No Cuts set for this metric</span>}
          {metric.points_of_contact && metric.points_of_contact.length > 0
            && listGeneration('Points of contact:', metric.points_of_contact)}
        </>,
    },
  }))
}

export default ({ id, metrics }:
  { id: string, metrics: Metric[] }) => (
  <>
    <div className="govuk-heading-l">
      <div className="flex-row">
        {`${metrics.length} metric${metrics.length !== 1 ? 's' : ''}`}
      </div>
    </div>
    <Accordion
      id={`metrics_accordian_${id}`}
      key={`metrics_accordian_${id}`}
      items={accordionItems2(metrics)}
    />
  </>
);

