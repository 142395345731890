import React, { ChangeEvent, MouseEvent, useState } from 'react';
import {
  Button, Fieldset, Input, WarningText,
} from 'govuk-react-jsx';
import { FieldsetLegend } from '../components/govukTypes';
import DateDescription from '../components/DateDescription';
import InternalError from '../pages/InternalError';
import { CancellableRefresher } from '../api/api';
import { create } from '../api/acquisitions';

type CreationFields = {
  hours: number,
  message: string,
};

/**
 * Create a handler for setting state given a change event.
 * @param setState for form fields
 * @returns function receiving change event and calling given setState with changed value
 */
const formChangeHandler = (
  setState: React.Dispatch<React.SetStateAction<CreationFields | Error>>,
) => (event: ChangeEvent<HTMLInputElement>) => {
  const { name, value } = event.currentTarget;
  // Convert to number if numerical field
  const fieldValue = name as keyof CreationFields === 'hours'
    ? Number.parseInt(value, 10)
    : value;
  setState((prevState) => ({ ...prevState, [name]: fieldValue }));
};

/**
 * Submitting the form given the state of the form.
 * @param event instigating submission
 * @param commissionID for form
 * @param state of form
 */
const formSubmit = async (
  event: MouseEvent<HTMLButtonElement>, dataSourceID: string, state: CreationFields,
  setState: React.Dispatch<React.SetStateAction<CreationFields | Error>>,
) => {
  const button = event.currentTarget;
  const buttonText = button.textContent;
  button.disabled = true;
  button.textContent = 'Creating…';
  try {
    await create(dataSourceID, state.hours, state.message);
  } catch (err) {
    setState(err);
  }
  button.textContent = buttonText;
  button.disabled = false;
};

export default (
  { dataSourceID, refresh }:
    { dataSourceID: string, refresh: CancellableRefresher },
) => {
  const [state, setState] = useState<CreationFields | Error>({ hours: 5, message: '' });
  const formChange = formChangeHandler(setState);

  if (state instanceof Error) {
    return <InternalError errorMessage={state.message} />
  }
  return (
    <>
      <WarningText iconFallbackText="Note">
        <p className="govuk-body">Manually creating a request is in addition to any automatic requests.</p>
      </WarningText>
      <Fieldset
        legend={{
          children: 'Create a request',
          className: 'govuk-fieldset__legend--l',
        } as FieldsetLegend}
      >
        <Input
          label={{ children: 'Due in hours' }}
          hint={{ children: 'Number of hours from now the request is due' }}
          formGroup={{ className: 'govuk-!-margin-bottom-2' }}
          id="create-request-due-hours"
          name="hours"
          type="number"
          className="govuk-input--width-2"
          onChange={formChange}
          value={Number.isNaN(state.hours) ? '' : state.hours}
        />
        <p className="govuk-body-m">
          {
            !Number.isNaN(state.hours)
              ? (
                <DateDescription
                  date={(() => {
                    const now = new Date();
                    now.setHours(now.getHours() + state.hours);
                    return now;
                  })()}
                  showTime
                  delimiter=", "
                />
              )
              : 'Enter a number of hours'
          }
        </p>
        <Input
          label={{ children: 'Message' }}
          hint={{ children: 'Optional, shown in request email' }}
          name="message"
          type="text"
          onChange={formChange}
          value={state.message}
          spellCheck
        />
        <Button
          type="submit"
          disabled={(!state.hours && state.hours !== 0) || state.hours < 0 || state.hours === 0}
          onClick={(event: MouseEvent<HTMLButtonElement>) => (
            formSubmit(event, dataSourceID, state, setState)
              .finally(() => refresh())
          )}
        >
          Create
        </Button>
      </Fieldset>
    </>
  );
};
