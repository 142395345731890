import get from './api';

export type Metric = {
  id: string,
  name: string,
  cut: string[],
  schedule: string,
  points_of_contact: string[],
  history_to_request?: number[],
  feed_type?: string,
};

export type Update = {
  at: number,
  action: string,
  status: string,
  message?: string,
  file_name?: string,
}

export type Status = {
  acquisition_id: number,
  updates?: Update[],
}

export type DataSource = {
  id: string,
  name: string,
  doc: string,
  type: string,
  metrics?: Metric[],
  schedule?: string,
  status?: Status[],
  enabled: boolean,
};

export async function getDataSource(id: string, abortSignal?: AbortSignal): Promise<DataSource> {
  return get(`v1/data_sources/${id}`, abortSignal);
}

export async function getDataSources(abortSignal?: AbortSignal): Promise<DataSource[]> {
  return get('v1/data_sources/', abortSignal);
}

export async function getDataSourceStatus(id: string, limit: number, abortSignal?: AbortSignal)
  : Promise<Status[]> {
  return get(`v1/data_sources/${id}/acquisitions?limit=${limit}`, abortSignal);
}
