/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
import React, { MouseEvent, useState } from 'react';
import { Update } from '../api/data_sources';
import { expire } from '../api/acquisitions';
import InternalError from '../pages/InternalError';
import { CancellableRefresher } from '../api/api';


const deleteAqu = async (
    dataSourceID: string, acquisitionID: number, event: MouseEvent<HTMLButtonElement>,
    setState: React.Dispatch<React.SetStateAction<Error | undefined>>,
) => {
    const button = event.currentTarget;
    const buttonText = button.textContent;
    button.disabled = true;
    button.textContent = 'Expiring...';
    try {
        await expire(acquisitionID, dataSourceID);
    } catch (err) {
        setState(err);
    }
    button.textContent = buttonText;
    button.disabled = false;
};
export default (
    {
        dataSourceID, acquisitionID, updates, refresh,
    }
        : {
            dataSourceID: string,
            acquisitionID: number,
            updates: Update[] | null | undefined,
            refresh: CancellableRefresher,
        },
) => {
    const expireID = `expire-acquisition-${acquisitionID}`
    const [state, setState] = useState<Error | undefined>();
    if (state instanceof Error) {
        return <InternalError errorMessage={state.message} />
    }

    if (!updates || updates.length === 0) {
        // There are no updates, show the expire section
        return (
            <>
                <details className="govuk-details" data-module="govuk-details">
                    <summary className="govuk-details__summary">
                        <span className="govuk-details__summary-text">
                            I want to expire this request.
                        </span>
                    </summary>
                    <div className="govuk-details__text">
                        <div className="govuk-warning-text">
                            <span className="govuk-warning-text__icon" aria-hidden="true">!</span>
                            <strong className="govuk-warning-text__text">
                                <span className="govuk-warning-text__assistive">Warning</span>
                                This will stop any responses
                                from being processed for this acquisition.
                            </strong>
                        </div>

                        <button
                            id={expireID}
                            className="govuk-button govuk-button--warning"
                            data-module="govuk-button"
                            type="submit"
                            onClick={(event: MouseEvent<HTMLButtonElement>) => (
                                deleteAqu(dataSourceID, acquisitionID, event, setState)
                            ).finally(() => refresh())}
                        >
                            Confirm and Expire
                        </button>
                    </div>
                </details>

            </>
        );
    }

    return (<></>);
};
