import { Tag } from 'govuk-react-jsx';
import React, { ReactNode } from 'react';
import DateDescription from '../../components/DateDescription';

/**
 * Get direction symbol for delta.
 * @param delta to show symbol for
 */
function deltaSymbol(delta: number): string {
  if (delta > 0) return '▲';
  if (delta < 0) return '▼';
  return '=';
}

/**
 * Get string description with symbol for numeric delta.
 * @param delta
 */
function deltaDescription(delta: number) {
  return `${delta}% ${deltaSymbol(delta)}`;
}

/**
 * Get colour to represent delta.
 * @param delta to be represented
 * @param increaseIsGood then green when positive
 */
function deltaColour(delta: number, increaseIsGood: boolean) {
  if (delta > 0) {
    return increaseIsGood ? 'green' : 'red';
  }
  return increaseIsGood ? 'red' : 'green';
}

/**
 * Represent some statistic as a number or percentage if total is known,
 * with title and comparison to yesterday's value with change calculated.
 *
 * @param name of statistic as title
 * @param current value of statistic now
 * @param previous value of statistic at COP yesterday
 * @param total if value is out of total
 * @param increaseIsGood if increase of value since yesterday should be shown positively
 */
export default ({
  name, current, previous, total, increaseIsGood,
}: {
  name: ReactNode, current: number, previous?: number, total?: number, increaseIsGood?: boolean
}) => {
  const figure = total ? (
    <>
      {`${Math.round((current / total) * 100)}% `}
      <span className="govuk-body">{`${current} / ${total}`}</span>
    </>
  ) : current;
  return (
    <>
      <span className="govuk-caption-l">{name}</span>
      <span className="govuk-heading-xl govuk-!-margin-bottom-1">{figure}</span>
      <span className="govuk-caption-m">
        <DateDescription date={new Date()} showTime />
      </span>
      {previous !== undefined && (
        <span className="govuk-caption-m">
          <Tag
            className={`govuk-tag--${deltaColour(current - previous, !!increaseIsGood)}`}
          >
            {deltaDescription(Math.round(((current - previous) / current) * 100))}
          </Tag>
          {` ${total ? `${Math.round((previous / total) * 100)}%` : previous} on `}
          <DateDescription date={new Date(new Date().getTime() - 86400000)} />
        </span>
      )}
    </>
  );
};
