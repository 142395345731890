type StatusSummary = {
  metrics: number,
  metricsReturned: number,
  commissions: number,
  commissionsReturned: number,
  status: ReturnStatus,
};

interface StatusBySubcategory {
  [key: string]: StatusSummary
}

/**
 * Get the paramount return status from given status summaries.
 * @param statuses
 * @return status or -Infinity if no statuses found
 */
export function overallReturnStatus(...statuses: { status: ReturnStatus }[]): ReturnStatus {
  return Math.max(...statuses.flatMap((value) => (typeof value.status === 'number' ? [value.status] : [])));
}

export interface SubcategoryByCategory {
  [key: string]: StatusBySubcategory
}

export enum ReturnStatus {
  extracted, valid,
  approved, rejected, approval,
  invalid, processing,
  submitted, late,
  waiting, expired, overdue
}

export const apiReturnStatus: { [key: string]: ReturnStatus } = {
  'Return Overdue': ReturnStatus.overdue, // schedule interval has elapsed without return
  'Awaiting Return': ReturnStatus.waiting, // user hasn't submitted yet
  'Awaiting Processing': ReturnStatus.processing, // user has submitted but processing hasn't returned yet
  Invalid: ReturnStatus.invalid, // processing or approval deemed invalid
  'Awaiting Approval': ReturnStatus.approval, // needs manual approval
  Rejected: ReturnStatus.rejected,
  Approved: ReturnStatus.approved, // ready
  Submitted: ReturnStatus.submitted,
  'Submitted Late': ReturnStatus.late,
  Valid: ReturnStatus.valid,
  Extracted: ReturnStatus.extracted, // like valid but automatic
  Expired: ReturnStatus.expired, // like waiting but will never be fulfilled
} as const;

/**
 * Sum number of returned commissions or metrics.
 * @param info to iterate through
 * @param key to choose what to sum
 */
export function sumReturned(info: StatusBySubcategory, key: 'commissions' | 'metrics'): [count: number, total: number] {
  return Object.values(info)
    .reduce(([accReturned, accTotal], cur) => (
      [accReturned + (cur[`${key}Returned` as keyof StatusSummary] ?? 0), accTotal + (cur[key] ?? 0)]
    ), [0, 0]);
}
