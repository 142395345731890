/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
import React, { useState } from 'react';
import { Button } from 'govuk-react-jsx';
import Dialog from '@reach/dialog';
import '@reach/dialog/styles.css';
import InternalError from '../pages/InternalError';


export default ({ downloadFile, buttonLabel, dialogMessage }
    : { downloadFile: any, buttonLabel: String, dialogMessage: String, }) => {
    const [downloadModalOpen, setDownloadModalOpen] = useState<boolean | Error>(false);
    // There are failures, show the download form
    return (
        <>
            { downloadModalOpen instanceof Error
                ? (
                    // There was an error with the download...
                    // show the error message in the modal
                    <Dialog
                        isOpen
                        onDismiss={() => { setDownloadModalOpen(false) }}
                    >
                        <div style={{ marginTop: '10px', marginBottom: '50px' }}>
                            <InternalError errorMessage={downloadModalOpen.message} />
                            <Button
                                type="button"
                                onClick={() => {
                                    setDownloadModalOpen(false);
                                }}
                                style={{ float: 'right' }}
                            >
                                Close
                            </Button>
                        </div>
                    </Dialog>
                )
                : (
                    { downloadModalOpen } && (
                        // No error and modal...
                        // show confirmation message and download button
                        <Dialog
                            isOpen={downloadModalOpen}
                            onDismiss={() => { setDownloadModalOpen(false) }}
                        >
                            <div className="govuk-grid-row">
                                <h1 className="govuk-heading-l">Are you sure?</h1>
                                <p className="govuk-body">
                                    {dialogMessage}
                                </p>
                                <Button
                                    type="button"
                                    onClick={async () => {
                                        try {
                                            downloadFile();
                                            setDownloadModalOpen(false);
                                        } catch (e) {
                                            setDownloadModalOpen(e);
                                        }
                                    }}
                                    style={{ marginRight: '10px' }}
                                >
                                    Download
                                </Button>
                                <Button
                                    type="button"
                                    onClick={() => {
                                        setDownloadModalOpen(false);
                                    }}
                                    style={{ float: 'right' }}
                                >
                                    Close
                                </Button>
                            </div>
                        </Dialog>
                    )
                )}
            <Button
                type="submit"
                onClick={() => {
                    setDownloadModalOpen(true);
                }}
            >
                {buttonLabel}
            </Button>
        </>
    );
};
