import React from 'react';
import { Details } from 'govuk-react-jsx';

export default ({ errorMessage }: { errorMessage: string }) => (
  <div className="govuk-grid-row">
    <div className="govuk-grid-column-two-thirds">
      <h1 className="govuk-heading-l">Sorry, there is a problem with the service</h1>
      <p className="govuk-body">Try again later.</p>
      {errorMessage && <Details summaryChildren="Technical information">{errorMessage}</Details>}
    </div>
  </div>
);
