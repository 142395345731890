import React, { useEffect, useState } from 'react';
import {
  Route, BrowserRouter as Router, Switch, RouteChildrenProps,
} from 'react-router-dom';
import { Template } from 'govuk-react-jsx';
import { Auth } from '@aws-amplify/auth';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { userIsPermitted, userIsAdmin } from './auth/config';
import BadGroup from './auth/BadGroup';
import { HeaderProps } from './components/govukTypes';
import ErrorBoundary from './components/ErrorBoundary';
import BreadcrumbNav from './components/BreadcrumbNav';
import NotFound from './pages/NotFound';
import SummaryPage from './summary/SummaryPage';
import DataSourceDetailPage from './detail/DataSourceDetailPage';

const title = 'Data Acquisition Dashboard';

const skiplink = { href: '#main-content', children: 'Skip to main content' };

export default () => {
  const header: HeaderProps = {
    homepageUrlHref: null,
    homepageUrlTo: '/',
    serviceName: title,
    serviceUrlTo: '/',
    containerClassName: 'govuk-width-container nav-float-right',
    navigation: null,
  };
  const [authState, setAuthState] = useState<AuthState>();
  const [user, setUser] = useState<CognitoUser>();
  useEffect(() => onAuthUIStateChange((nextAuthState, data) => {
    setAuthState(nextAuthState);
    if (data) setUser(data as CognitoUser);
  }), []);
  useEffect(() => {
    if (authState !== AuthState.SignedIn) return;
    const controller = new AbortController();
    return () => controller.abort();
  }, [authState]);

  if (!user || authState !== AuthState.SignedIn) {
    return (
      <Router>
        <Template
          {...{ skiplink, header }}
          title={title}
        >
          <ErrorBoundary>
            <AmplifyAuthenticator>
              <AmplifySignIn slot="sign-in" hideSignUp />
            </AmplifyAuthenticator>
          </ErrorBoundary>
        </Template>
      </Router>
    );
  }
  header.navigation = [{ children: ['Log out'], to: '/', onClick: () => Auth.signOut() }];
  if (!userIsPermitted(user)) {
    return (
      <Router>
        <Template
          {...{ skiplink, header }}
          title={title}
        >
          <ErrorBoundary>
            <BadGroup />
          </ErrorBoundary>
        </Template>
      </Router>
    );
  }
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Template
            {...{ skiplink, header }}
            title={title}
          >
            <ErrorBoundary>
              <SummaryPage isAdmin={userIsAdmin(user)} />
            </ErrorBoundary>
          </Template>
        </Route>
        <Route path="/data_source/:id" id="">
          {({ match }: RouteChildrenProps<{ id: string }>) => (
            <Template
              {...{ skiplink, header }}
              title={match?.params && `${match?.params.id} - ${title}`}
              beforeContent={(
                <>
                  <BreadcrumbNav dataSourceID={match?.params.id} />
                </>
              )}
            >
              <ErrorBoundary>
                <DataSourceDetailPage dataSourceID={match?.params.id || ''} isAdmin={userIsAdmin(user)} />
              </ErrorBoundary>
            </Template>
          )}
        </Route>
        <Route path="*">
          <Template
            {...{ skiplink, header }}
            title={`Page not found - ${title}`}
          >
            <NotFound />
          </Template>
        </Route>
      </Switch>
    </Router>
  );
};
