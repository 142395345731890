import React from 'react';
import { ErrorSummary } from 'govuk-react-jsx';

/**
 * Error message to show when the user's logged in account is unable to access the APIs
 * needed for the dashboard.
 *
 * When the component is presented, it immediately grabs tab focus for screen readers to read it.
 * Something to keep in mind if this component is used alongside the login form in the future.
 */
export default () => <ErrorSummary errorList={[{ children: 'Your account does not have access to this application.' }]} />;
