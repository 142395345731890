import React, { MouseEvent } from 'react';

type ButtonEvent = MouseEvent<HTMLButtonElement>;

/**
 * Event handler generator for performing the given action when a button event occurs.
 * Prevents double-clicks on the button by disabling the button for half a second and
 * updates the button text during execution.
 * The button's updates do not await for the set action to be performed, so the action can be
 * executing in parallel if it takes longer than half a second to complete.
 * @param action to perform when the button is clicked
 * @returns event handler
 */
const actionHandler = (action: (event: ButtonEvent) => any) => (event: ButtonEvent) => {
  action(event);
  const button = event.currentTarget;
  const originalText = button.textContent;
  button.textContent = 'Loading…';
  button.disabled = true;
  setTimeout(() => {
    button.textContent = originalText;
    button.disabled = false;
  }, 500);
};

/**
 * Button to refresh content. Updates its own title and disables itself for half a second
 * after click to reflect an action taking place.
 * @param suffix to show on button title
 * @param action to perform when button is clicked
 */
export default (
  { suffix, action }: { suffix?: string, action: (event: MouseEvent<HTMLButtonElement>) => any },
) => (
  <button type="button" className="govuk-body-s govuk-link govuk-link--no-visited-state govuk-!-margin-bottom-0" onClick={actionHandler(action)}>{`Refresh${suffix ? ` ${suffix}` : ''}`}</button>
);
