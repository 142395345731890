/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
import React, {
    useEffect, ReactNode,
} from 'react';
import {
    Accordion, Details, SummaryList, Tag,
} from 'govuk-react-jsx';
import { Status, Update, Metric } from '../api/data_sources';
import RefreshButton from '../components/RefreshButton';
import StatusItem from '../components/Status';
import AcquisitionCreation from './AcquisitionCreation';
import ExpireButton from './ExpireButton';
import { CancellableRefresher } from '../api/api';
import ManualUpload from './ManualUpload';
import FileSubmit from './FileSubmit';
import { resubmitFile } from '../api/commissions';

const updatesDetail = (
    dataSourceID: string,
    acquisitionID: number,
    refresh: CancellableRefresher,
    isAdmin?: boolean,
    updates?: Update[],
    allowCreate?: boolean,
    )
    : ReactNode => {
    if (!updates || updates.length === 0) {
        return <span className="govuk-caption-m">0 updates</span>
    }

    return (
          <>
              <span className="govuk-grid-column-one-half-from-desktop govuk-caption-m">
                  {`${updates.length} update${updates.length > 1 ? 's' : ''}`}
              </span>
              {((updates.length > 0) && (updates[updates.length - 1].status === 'failure') && (updates[updates.length - 1].action === 'validate')) && isAdmin && allowCreate && (
                  <FileSubmit
                          fileSubmission={async (file: File) => {
                              await resubmitFile(dataSourceID, acquisitionID.toString(), file);
                          }}
                          refresh={refresh}
                  />
              )}
              <SummaryList
                  className="summary-flexible"
                  rows={updates.map((u) => ({
                      key: {
                          children: [
                              `${new Date(u.at / 1000).toLocaleString('en-GB')}: ${u.action.toUpperCase()}`,
                              <br />,
                              (u.message) ? <span className="govuk-body-m">{u.message}</span> : '',
                          ],
                      },
                      value: {
                          children: (() => {
                              let colour = 'inactive';
                              if (u.status === 'success') {
                                  colour = 'green';
                              } else if (u.status === 'failure') {
                                  colour = 'red';
                              }
                              return <Tag className={`govuk-tag--${colour}`}>{u.status}</Tag>;
                          })(),
                      },
                  }))}
              />
          </>
      )
  }



  export default (
      {
          isAdmin, dataSourceID, allowCreate, filteredAcquisitions, refresh, metrics,
      }:
          { isAdmin: boolean,
            dataSourceID: string,
            allowCreate: boolean,
            filteredAcquisitions: Status[],
            refresh: CancellableRefresher,
            metrics?: Metric[]},
  ) => {
      useEffect(() => {
          refresh();
      }, []);

      return (
          <>
              <h2 className="govuk-heading-l flex-row">
                  {`Most recent ${filteredAcquisitions.length} acquisition${filteredAcquisitions.length !== 1 ? 's' : ''}`}
                  {' '}
                  <RefreshButton suffix="acquisitions" action={() => refresh()} />
              </h2>
              {allowCreate && isAdmin && (
                  <div>
                      <Details summaryChildren="Request acquisition">
                          <AcquisitionCreation dataSourceID={dataSourceID} refresh={refresh} />
                      </Details>
                      <Details summaryChildren="Manual Acquisition Upload">
                          <ManualUpload
                              dataSourceID={dataSourceID}
                              metrics={metrics}
                              refresh={refresh}
                          />
                      </Details>
                  </div>
              )}
              {filteredAcquisitions.length > 0 && (
                  <Accordion
                      id={`acquisition-${filteredAcquisitions.map((value) => value.acquisition_id).join()}`}
                      key={Math.random().toString()}
                      items={filteredAcquisitions.map((value) => ({
                          heading: {
                              children: [
                                  <div className="govuk-grid-column-one-half-from-desktop govuk-heading-m monospace">{value.acquisition_id}</div>,
                                  <StatusItem status={value} />,
                              ],
                          },
                          content: {
                              children:
                                  <>
                                  {isAdmin && (
                                      <ExpireButton
                                          dataSourceID={dataSourceID}
                                          acquisitionID={value.acquisition_id}
                                          updates={value.updates}
                                          refresh={refresh}
                                      />
                                    )}
                                      {updatesDetail(
                                          dataSourceID,
                                          value.acquisition_id,
                                          refresh,
                                          isAdmin,
                                          value.updates,
                                          allowCreate,
                                      )}
                                  </>
                              ,
                          },
                      }))}
                  />
              )}
          </>
      );
  }
