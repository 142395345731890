import { Auth } from '@aws-amplify/auth';

export type CancellableRefresher = (abortSignal?: AbortSignal) => Promise<void>;

/**
 * API domain with trailing slash.
 */
const api = process.env.REACT_APP_API_BASE_URL;

/**
 * Get Authorization header containing JWT token from current user's authenticated session.
 */
async function getAuthHeader() {
  try {
    return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
  } catch (e) {
    return { Authorization: '' };
  }
}

/**
 * Make GET request to endpoint.
 * @param endpoint appended to domain without slash prefix
 * @param abortSignal to cancel request
 */
export default async function get(endpoint: string, abortSignal?: AbortSignal) {
  const response = await fetch(`${api}${endpoint}`, {
    headers: {
      ...await getAuthHeader(),
      Accept: 'application/json',
    },
    signal: abortSignal,
  });
  if (!response.ok) {
    throw new Error(`Unable to fetch ${endpoint}: ${response.status}`);
  }
  return response.json();
}

/**
 * Make POST request with optional body as JSON string.
 * @param endpoint appended to domain without slash prefix
 * @param body to be JSON stringified
 */
export async function post(endpoint: string, body?: any) {
  const response = await fetch(`${api}${endpoint}`, {
    method: 'POST',
    headers: {
      ...await getAuthHeader(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...body && { body: JSON.stringify(body) },
  });
  if (!response.ok) {
    throw new Error(`Unable to POST ${endpoint}: ${response.status}`);
  }
  return response.json();
}

export async function patch(endpoint: string, body?: any) {
  const response = await fetch(`${api}${endpoint}`, {
    method: 'PATCH',
    headers: {
      ...await getAuthHeader(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...body && { body: JSON.stringify(body) },
  });
  if (!response.ok) {
    throw new Error(`Unable to PATCH ${endpoint}: ${response.status}`);
  }
  return response.json();
}


export async function del(endpoint: string, abortSignal?: AbortSignal) {
  const response = await fetch(`${api}${endpoint}`, {
    method: 'DELETE',
    headers: {
      ...await getAuthHeader(),
      Accept: 'application/json',
    },
    signal: abortSignal,
  });
  if (!response.ok) {
    throw new Error(`Unable to DELETE ${endpoint}: ${response.status}`);
  }
  return response.json();
}

export async function placeFile(
  path: string,
  apiMethod: string,
  abortSignal?: AbortSignal,
  postData?: string,
): Promise<Response> {
  const apiPath = api + path;
  const auth = `${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
  const response = postData ? await fetch(apiPath, {
    headers: {
      Authorization: auth,
    },
    signal: abortSignal,
    method: apiMethod,
    body: postData,
  }) : await fetch(apiPath, {
    headers: {
      Authorization: auth,
    },
    signal: abortSignal,
  });

  return response;
}
