import type { CognitoUser } from 'amazon-cognito-identity-js';
import type { AmplifyConfig } from 'aws-amplify/lib/Common/types/types';

export default {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    mandatorySignIn: true,
  },
} as AmplifyConfig;

/**
 * User's ID token must contain one of these groups to be able to use the dashboard.
 * This is meant as a preliminary check before calling APIs fails. Make sure groups here
 * also have access to the API, otherwise the user will be taken to the ‘something wrong’ page
 * when the API calls fail.
 */
const groupsPermitted = ['tracker_users', 'tracker_admins'];
const adminGroups = ['tracker_admins'];

/**
 * Check if the given user is permitted to access the dashboard.
 * @param user to check
 * @returns if the user is permitted access
 */
export function userIsPermitted(user: CognitoUser): boolean {
  const userGroups: string[] = user?.getSignInUserSession()?.getIdToken().decodePayload()['cognito:groups'] ?? [];
  return groupsPermitted.some((group) => userGroups.includes(group));
}

/**
* Check if the given user is an admin
* @param user to check
* @returns if the user is an admin
*/
export function userIsAdmin(user: CognitoUser): boolean {
  const userGroups: string[] = user?.getSignInUserSession()?.getIdToken().decodePayload()['cognito:groups'] ?? [];
  return adminGroups.some((group) => userGroups.includes(group));
}
