import { patch } from './api';

export type DataSource = {
  id: string,
  name: string,
  doc: string,
  type: string,
  schedule?: string,
  enabled: boolean,
};

export async function patchDataSet(
  dataSourceID: string, enabled: boolean,
): Promise<DataSource> {
  return patch(`v1/data_sources/${dataSourceID}`, { enabled });
}
