/* eslint-disable react/jsx-indent */
import React from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Table } from 'govuk-react-jsx';
import { AccordionItem } from '../components/govukTypes';
import { DataSource } from '../api/data_sources';
import Status from '../components/Status';
import { Sort, SortableOptions } from './sortTypes';

function accordionItems(
  dataSources: DataSource[],
  sort: Sort,
): AccordionItem[] {
  // Group items by name
  const grouped: Record<string, DataSource[]> = {};
  dataSources.forEach((ds) => {
    const updated = (grouped[ds.name] || []);
    updated.push(ds);
    grouped[ds.name] = updated;
  }, {});

  // Turn into accordian
  // eslint-disable-next-line max-len
  const keys = sort.sortBy === SortableOptions.department ? Object.keys(grouped).sort() : Object.keys(grouped)

  return keys.map((name) => {
    const sources = grouped[name];
    const infoRows = sources.map((ds) => (
      [
        // Name of data source
        {
          children:
            <Link to={`/data_source/${ds.id}`} className="govuk-link--no-visited-state">{`${ds.id}`}</Link>,
          className: 'govuk-!-width-one-third',
        },
        // Status
        {
          children:
            <Status status={ds.status && ds.status.length > 0 ? ds.status[0] : undefined} />,
        },
      ]
    ));
    return {
      heading: {
        children: (
          <>
            <div className="govuk-grid-column-one-third-from-desktop govuk-!-padding-0">{name}</div>
            {infoRows.length > 1 ? (
              <div className="govuk-grid-column-two-thirds-from-desktop">
                <span className="govuk-body">{`${infoRows.length} data sources`}</span>
              </div>
            )
              : (
                <div className="govuk-grid-column-two-thirds-from-desktop">
                  <Table className="govuk-table--vertical-align-middle" rows={infoRows} />
                </div>
              )}
          </>
        ),
      },
      content: {
        children: (
          <Table
            className="govuk-table--vertical-align-middle"
            head={[{ children: 'Data Source' }, { children: 'Latest Status' }]}
            rows={infoRows}
          />
        ),
      },
    };
  });
}

export default (
  { dataSources, sort }:
    { dataSources: DataSource[], sort: Sort },
) => (
  <Accordion
    id="commissionCategories"
    // It is important that the key is dependant on the data...
    // otherwise changes to the data don't seem to fully update the component
    key={dataSources.map((ds) => ds.name).join('-')}
    items={
      accordionItems(dataSources, sort)
    }
  />
);
