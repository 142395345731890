/**
 * Initialise a new RelativeTimeFormat with polyfill support.
 */
export async function relativeFormatter() {
  if (!('RelativeTimeFormat' in Intl)) {
    await import('intl-relative-time-format');
    await import('intl-relative-time-format/locale-data/en-GB');
  }
  return new Intl.RelativeTimeFormat('en-GB', {
    localeMatcher: 'best fit',
    numeric: 'auto',
    style: 'narrow',
  });
}

/**
 * Calculate the delta in days between the current time and the given date.
 * @param date to calculate delta to
 */
export function dayDelta(date: Date): number {
  return Math.round((date.getTime() - Date.now()) / 86400000);
}

/**
 * Describe the given date.
 * @param date to describe
 */
export function dayDescription(date: Date): string {
  if (date.getTime() === 0) return '';
  return Intl.DateTimeFormat('en-GB', {
    weekday: 'long',
    day: 'numeric',
    month: 'numeric',
    ...date.getFullYear() !== new Date().getFullYear() && { year: 'numeric' },
    timeZone: 'Europe/London',
  }).format(date);
}

/**
 * Describe the time of the given date.
 * @param date to describe
 */
export function timeDescription(date: Date): string {
  if (date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0) return '';
  return date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
}
