import React from 'react';
import { Breadcrumbs } from 'govuk-react-jsx';
import { LinkProps } from 'react-router-dom';

/**
 * Breadcrumb navigation component intended for beforeContent in GOV.UK Template.
 *
 * Requires ID for second link as no breadcrumb is shown when at the first level of hierarchy.
 * All links are router links so this must be within a `<Router>`.
 *
 * @param dataSourceID to create second link
 */
export default ({ dataSourceID }: { dataSourceID?: string }) => (
  <Breadcrumbs
    items={[
      { children: 'Home', to: '/' },
      { children: `${dataSourceID}`, to: `/data_source/${dataSourceID}` },
    ] as LinkProps[]}
  />
);
