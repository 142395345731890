import { encode as utf8Encode } from 'utf8';
import get, { post, placeFile } from './api';
import { apiReturnStatus, ReturnStatus } from './statuses';

enum ThreeScale {
  high = 'High', medium = 'Medium', low = 'Low'
}

type Schedule = {
  id: number,
  name: string,
  cron: string,
  interval: number,
};

interface Schedulable {
  schedule: Schedule,
  status: ReturnStatus,
}

export type Metric = {
  id: number,
  commissionID: number,
  name: string,
  category: string,
  subcategory?: string,
  cuts: Cut[],
  datasource: string,
  frequency: string,
  sensitivity?: ThreeScale,
  confidence?: ThreeScale,
} & Partial<Schedulable>;

type Contact = {
  id: number,
  name?: string,
  email?: string,
  phone?: string,
};

type Cut = {
  cutName: string
}

enum Trend {
  good, warning, urgent
}

type CommissionConfigState = {
  message: string,
};

export type Commission = {
  id: number,
  datasource: string,
  contacts: Contact[],
  metrics: Metric[],
  trend: Trend[],
} & Partial<Schedulable>;

const parseAPISchedule = ({
  scheduleID: id, scheduleName: name, cron, interval,
}: { scheduleID: number, scheduleName: string, cron: string, interval: number }) => ({
  id, name, cron, interval,
});

const parseAPICommissionID = (commissionID: string) => Number.parseInt(commissionID.replace('COM_', ''), 10);

function parseAPITrend(trend: string): Trend {
  switch (trend) {
    case 'Urgent': return Trend.urgent;
    case 'Warning': return Trend.warning;
    case 'Good':
    default: return Trend.good;
  }
}

function parseAPICommission(apiCommission: {
  commissionID: Parameters<typeof parseAPICommissionID>[0],
  commissionName: string,
  contacts: {
    contactID: number,
    contactName?: string,
    contactPhone?: string,
    contactEmail?: string,
  }[],
  status?: string,
  metrics?: {
    metricID: number,
    metricName: string,
    category: string,
    subCategory?: string,
    cuts: Cut[],
    confidence: string,
    sensitivity: string,
    status: string,
    dataSource: string,
    frequency: string,
    schedule?: Parameters<typeof parseAPISchedule>[0],
  }[],
  schedule?: Parameters<typeof parseAPISchedule>[0],
  commissionHealth?: Parameters<typeof parseAPITrend>[0][],
}): Commission {
  const commissionID = parseAPICommissionID(apiCommission.commissionID);
  return {
    id: commissionID,
    datasource: apiCommission.commissionName,
    contacts: apiCommission.contacts.map((apiContact) => ({
      id: apiContact.contactID,
      ...apiContact.contactName && { name: apiContact.contactName },
      ...apiContact.contactEmail && { email: apiContact.contactEmail },
      ...apiContact.contactPhone && { phone: apiContact.contactPhone },
    })),
    metrics: (apiCommission.metrics ?? []).map((apiMetric) => ({
      id: apiMetric.metricID,
      commissionID,
      name: apiMetric.metricName,
      category: apiMetric.category,
      subcategory: apiMetric.subCategory,
      cuts: apiMetric.cuts,
      datasource: apiMetric.dataSource,
      confidence: apiMetric.confidence as ThreeScale,
      sensitivity: apiMetric.sensitivity as ThreeScale,
      ...apiMetric.status && {
        status: apiReturnStatus[apiMetric.status],
      },
      frequency: apiMetric.frequency,
      ...apiMetric.schedule && {
        schedule: {
          id: apiMetric.schedule.scheduleID,
          name: apiMetric.schedule.scheduleName,
          cron: apiMetric.schedule.cron,
          interval: apiMetric.schedule.interval,
        },
      },
    })),
    ...apiCommission.status && {
      status: apiReturnStatus[apiCommission.status],
    },
    ...apiCommission.schedule && {
      schedule: {
        id: apiCommission.schedule.scheduleID,
        name: apiCommission.schedule.scheduleName,
        cron: apiCommission.schedule.cron,
        interval: apiCommission.schedule.interval,
      },
    },
    trend: apiCommission.commissionHealth?.map(parseAPITrend) ?? [],
  };
}

export async function getCommission(id: number, abortSignal?: AbortSignal): Promise<Commission> {
  return parseAPICommission(await get(`commission/COM_${id}`, abortSignal));
}

export async function getCommissions(abortSignal?: AbortSignal): Promise<Commission[]> {
  return (await get('commission/', abortSignal)).map(parseAPICommission);
}

export async function triggerCommissionConfigPull():
Promise<CommissionConfigState> {
  return post('v1/data_sources');
}

export async function resubmitFile(
  dataSourceID: string,
  acquisitionID: string,
  file: File,
): Promise<void> {
  const response = await placeFile(`v1/data_upload/${dataSourceID}/acquisitions/${acquisitionID}`, 'PUT', undefined, utf8Encode(await file.text()));
  if (!response.ok) throw new Error(`${response.status}: ${await response.text()}`);
}

export async function manualSubmit(
  dataSourceID: string,
  file: File,
): Promise<void> {
  const response = await placeFile(`v1/data_upload/${dataSourceID}/acquisitions`, 'POST', undefined, utf8Encode(await file.text()));
  if (!response.ok) throw new Error(`${response.status}: ${await response.text()}`);
}
