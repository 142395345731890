import React from 'react';
import { Tag } from 'govuk-react-jsx';
import { Status } from '../api/data_sources';

export default (
    { status }:
        { status?: Status },
) => {
    // Default for no acquisitions
    let message = 'no acquisitions';
    let colour = 'inactive';

    if (status) {
        if (status.updates && status.updates.length > 0) {
            const latest = status.updates[status.updates.length - 1];
            const time = new Date(latest.at / 1000).toLocaleString('en-GB');
            message = `${time}: ${latest.action} - ${latest.status}`;
            if (latest.status === 'success') {
                colour = 'green';
            } else if (latest.status === 'failure') {
                colour = 'red';
            }
        } else {
            // There is an acquisition, but no updates
            const time = new Date(status?.acquisition_id / 1000).toLocaleString('en-GB');
            message = `${time}: requested`;
            colour = 'yellow';
        }
    }

    return <Tag className={`govuk-tag--${colour}`}>{`${message}`}</Tag>;
}
