/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
import React, { useState, SyntheticEvent } from 'react';
import { Button, FileUpload } from 'govuk-react-jsx';
import Dialog from '@reach/dialog';
import '@reach/dialog/styles.css';
import InternalError from '../pages/InternalError';
import { CancellableRefresher } from '../api/api';


export default ({
    fileSubmission, refresh,
}
    : {
        fileSubmission: any,
        refresh: CancellableRefresher,
    }) => {
    const [resubmitModalOpen, setResubmitModalOpen] = useState<boolean | Error>(false);

    return (
        <>
            { resubmitModalOpen instanceof Error
                ? (
                    // There was an error with the resubmission...
                    // show the error message in the modal
                    <Dialog
                        isOpen
                        onDismiss={() => { setResubmitModalOpen(false) }}
                    >
                        <div style={{ marginTop: '10px', marginBottom: '50px' }}>
                            <InternalError errorMessage={resubmitModalOpen.message} />
                            <Button
                                type="button"
                                onClick={() => {
                                    setResubmitModalOpen(false);
                                }}
                                style={{ float: 'right' }}
                            >
                                Close
                            </Button>
                        </div>
                    </Dialog>
                )
                : (
                    { resubmitModalOpen } && (
                        // No error and modal...
                        // show confirmation message and resubmission button
                        <Dialog
                            isOpen={resubmitModalOpen}
                            onDismiss={() => { setResubmitModalOpen(false) }}
                        >
                            <div className="govuk-grid-row">
                                <h1 className="govuk-heading-l">Are you sure?</h1>
                                <p className="govuk-body">
                                    Please ensure you are happy to submit this file.
                                </p>
                                <FileUpload
                                    id="file-upload"
                                    name="file-upload"
                                    label={{ children: ['Upload a file'] }}
                                    hint={{ children: ['Supported file types: CSV (.csv)'] }}
                                    onChange={
                                        async (event: SyntheticEvent<HTMLInputElement>) => {
                                    if (!event.currentTarget.files) {
                                        setResubmitModalOpen(Error('A file is required to use file upload.'));
                                        return;
                                    }
                                    try {
                                        const currentFile = event.currentTarget.files?.[0];
                                        await fileSubmission(
                                            currentFile,
                                            );
                                        refresh();
                                        setResubmitModalOpen(false);
                                    } catch (e) {
                                        setResubmitModalOpen(e);
                                    }
                                    }
                                }
                                    accept=".csv"
                                />
                                <Button
                                    type="button"
                                    onClick={() => {
                                        setResubmitModalOpen(false);
                                    }}
                                    style={{ float: 'right' }}
                                >
                                    Close
                                </Button>
                            </div>
                        </Dialog>
                    )
                )}
            <Button
                type="submit"
                onClick={() => {
                    setResubmitModalOpen(true);
                }}
            >
                Submit File
            </Button>
        </>
    );
};
