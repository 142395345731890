import { post, del } from './api';

export async function expire(acquisition_id: number, data_source_id: string): Promise<void> {
  await del(`v1/data_sources/${data_source_id}/acquisitions/${acquisition_id}/expire`);
}

export async function create(
  dataSourceID: string, hours: number, message: string,
): Promise<number> {
  return post(`v1/data_sources/${dataSourceID}/acquisitions`, { hours, message });
}
