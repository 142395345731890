import React from 'react';
import fileDownload from 'js-file-download';
import Download from './Download';
import { Metric } from '../api/data_sources';
import FileSubmit from './FileSubmit';
import { CancellableRefresher } from '../api/api';
import { manualSubmit } from '../api/commissions';

export default (
  { dataSourceID, metrics, refresh }:
    { dataSourceID: string, metrics: Metric[] | undefined, refresh: CancellableRefresher },
) => (
  <>
    <Download
      downloadFile={() => {
        // build a basic template
        let fileTemplate = 'Date,Metric ID,Metric Name,Cut,Metric Value,Notes\n';
        if (metrics instanceof Array) {
          // build up string to match required format
          const date = new Date().toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });
          for (let i = 0; i < metrics.length; i += 1) {
            // fill in template info
            let row = date;
            row += ',';
            row += metrics[i].id.toString();
            row += ',';
            row += metrics[i].name;
            row += ',';
            const cuts = metrics[i].cut;
            if ((cuts !== null) && (cuts.length >= 1)) {
              // just use the first cut for this example
              row += cuts[0];
              row += ',';
            } else {
              row += ' ,';
            }
            row += '\n';
            fileTemplate += row;
          }
        }
        fileDownload(fileTemplate, `${dataSourceID}.csv`, 'text/csv');
      }}
      buttonLabel="Download Template"
      dialogMessage="This template gives an example of the layout required to pass validation"
    />
    <FileSubmit
      fileSubmission={async (file: File) => {
        await manualSubmit(dataSourceID, file);
      }}
      refresh={refresh}
    />
  </>
);
