import React, { ComponentLifecycle, ErrorInfo, ReactNode } from 'react';
import InternalError from '../pages/InternalError';

type P = { children?: ReactNode };
type S = { errorMessage: string };

/**
 * Catch errors and present GOV.UK Design System pattern for something went wrong page.
 *
 * Based on layout of https://design-system.service.gov.uk/patterns/problem-with-the-service-pages/
 * Also shows given ‘technical information’ behind a disclosure.
 */
export default class extends React.Component<P, S> implements ComponentLifecycle<P, S> {
  constructor(props: P) {
    super(props);
    this.state = { errorMessage: '' };
  }

  componentDidCatch(error: Error, _: ErrorInfo) {
    this.setState({ errorMessage: `${error.name}: ${error.message}` });
  }

  render() {
    const { errorMessage } = this.state;
    if (errorMessage) return <InternalError errorMessage={errorMessage} />;
    const { children } = this.props;
    return children;
  }
}
