import React from 'react';

import { Tag } from 'govuk-react-jsx';

import cronstrue from 'cronstrue';

type P = { 'cron': (string | undefined) }
type S = { 'cronMessage': (string | undefined), 'cronError': string }

export default class Schedule extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      cronMessage: '',
      cronError: '',
    };
    this.changeCron = this.changeCron.bind(this);
  }

  componentDidMount() {
    this.changeCron()
  }

  changeCron() {
    const { cron } = this.props;
    try {
      if (cron === undefined) {
        this.setState({ cronMessage: '' });
      } else {
        const message = cronstrue.toString(cron, { dayOfWeekStartIndexZero: false });
        this.setState({ cronMessage: message });
      }
    } catch (e) {
      this.setState({ cronMessage: cron });
      this.setState({ cronError: String(e) });
    }
  }

  generateSchedule() {
    const { cronMessage } = this.state;
    const { cronError } = this.state;
    return (
      <>
        <div>
          {(cronError === '') && (
            <Tag className="govuk-tag--grey">{`Schedule: ${cronMessage}`}</Tag>
          )}
          {(cronError !== '') && (
            <>
              <Tag className="govuk-tag--inactive">{`Schedule: ${cronMessage}`}</Tag>
            </>
          )}
        </div>
      </>
    );
  }

  render() {
    const { cron } = this.props
    return (
      <>
        <div>
          {(cron !== undefined) || (
            <div className="govuk-grid-column-one-third">
              <Tag className="govuk-tag--yellow">NOT SCHEDULED</Tag>
            </div>
          )}
          {(cron === undefined) || (
            this.generateSchedule()
          )}
        </div>
      </>
    );
  }
}
