import React from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';
import Amplify from 'aws-amplify';
import authConfig from './auth/config';
import App from './App';

Amplify.configure(authConfig);

ReactDOM.render(<React.StrictMode><App /></React.StrictMode>, document.getElementById('root'));
