import React, {
  useCallback, useState, useEffect, SyntheticEvent, MouseEvent,
} from 'react';
import ReactMarkdown from 'react-markdown';
import {
  Checkboxes, Details, Tabs, Tag, Button,
} from 'govuk-react-jsx';
import { TabItem } from '../components/govukTypes';
import {
  getDataSourceStatus, DataSource, getDataSource, Status, Metric,
} from '../api/data_sources';
import InternalError from '../pages/InternalError';
import Acquisitions from './Acquisitions';
import Schedule from './Schedule';
import MetricsTable from './MetricsTable';
import { patchDataSet } from '../api/patch';

type Filters = {
  all: boolean,
  success: boolean,
  fail: boolean,
  actions: Map<string, boolean>,
};

const successFilter = (aquisition: Status) => {
  // return true if all updates were successful.
  let aquisitionSuccessful = false
  if (aquisition.updates) {
    let unsuccessfulStatusFound = false
    if (aquisition.updates.length === 0) {
      unsuccessfulStatusFound = true
    } else {
      Object.entries(aquisition.updates).forEach((updateEntry) => {
        const update = updateEntry[1]
        if (update.status !== 'success') {
          unsuccessfulStatusFound = true
        }
      })
    }

    if (unsuccessfulStatusFound === false) {
      // we found a successful status in the acquisition to return
      aquisitionSuccessful = true
    }
  }

  return aquisitionSuccessful
};

const failureFilter = (aquisition: Status) => {
  // return true if one update was not successful
  if (!aquisition.updates) {
    return false
  }
  return !successFilter(aquisition)
}

const actionFilter = (aquisition: Status, requiredAction: string) => {
  // return true if the required action is found.
  if (aquisition.updates) {
    if ((aquisition.updates.length === 0) && (requiredAction === 'requested')) {
      return true
    }

    if ((aquisition.updates.length > 0)
      && (aquisition.updates[aquisition.updates.length - 1].action === requiredAction)) {
      return true
    }
  }
  return false
}
const filterAcquisitions = (filters: Filters, aquisition: Status): boolean => {
  // this function is a working progress and needs work after the refactor
  let returnAcquisition = false
  if (filters.all === true) {
    returnAcquisition = true
  }

  if (filters.success === true) {
    returnAcquisition = successFilter(aquisition)
  }

  if (filters.fail === true) {
    returnAcquisition = failureFilter(aquisition)
  }

  filters.actions.forEach((actionValue, actionKey) => {
    if (actionValue) {
      // Filter for required action
      returnAcquisition = actionFilter(aquisition, actionKey)

      // if success or failure filter is applied apply it here too.
      if ((filters.success === true) && returnAcquisition) {
        returnAcquisition = successFilter(aquisition)
      } else if ((filters.fail === true) && returnAcquisition) {
        returnAcquisition = failureFilter(aquisition)
      }
    }
  })

  return returnAcquisition
};

const filtersToCheckboxes = (filters: Filters): Object[] => {
  const actionCheckboxs = Array(0);
  if (filters.actions.size > 0) {
    filters.actions.forEach(
      (entryValue, entryKey) => {
        actionCheckboxs.push(
          {
            checked: entryValue,
            children: entryKey.charAt(0).toUpperCase() + entryKey.slice(1),
            hint: {
              children: `Only show last ${entryKey.charAt(0).toUpperCase() + entryKey.slice(1)} actions`,
            },
            id: entryKey,
            name: entryKey,
            value: entryKey,
          },
        )
      },
    )
  }

  return [
    {
      checked: filters.all,
      children: 'All',
      hint: {
        children: 'Include all acquisitions',
      },
      id: 'item_acquisition',
      name: 'acquisition',
      value: 'acquisition', // used in switch statement to choose filter option
    },
    {
      checked: filters.success,
      children: 'Success',
      hint: {
        children: 'Only show successful acquisitions',
      },
      id: 'item_success',
      name: 'success',
      value: 'success',
    },
    {
      checked: filters.fail,
      children: 'Failure',
      hint: {
        children: 'Only show failed acquisitions',
      },
      name: 'fail',
      value: 'fail',
    },
    ...actionCheckboxs,
  ];
}

function metricsControlCheck(metrics: Metric[] | undefined) {
  if (metrics === undefined) {
    return false
  }
  for (let i = 0; i < metrics.length; i += 1) {
    if (metrics[i].feed_type !== 'Email Auto') {
      return false;
    }
  }
  return true;
}

const filtersUpdate = (current: Filters, changed: string): Filters => {
  const newFilters: Filters = { ...current };
  switch (changed) {
    case 'acquisition':
      newFilters.all = !newFilters.all;
      if (newFilters.all) {
        newFilters.success = false;
        newFilters.fail = false;
      }
      break;
    case 'success':
      newFilters.success = !newFilters.success;
      if (newFilters.success) {
        newFilters.all = false;
        newFilters.fail = false;
      }
      break;
    case 'fail':
      newFilters.fail = !newFilters.fail;
      if (newFilters.fail) {
        newFilters.all = false;
        newFilters.success = false;
      }
      break;
    default:
      // maybe filter on action seleted.
      if (newFilters.actions.has(changed)) {
        const previousState = newFilters.actions.get(changed)
        newFilters.actions.set(changed, !previousState)
        newFilters.actions.forEach((_, key, map) => {
          if (changed !== key) {
            map.set(key, false);
          }
        })
      }
      break;
  }
  return newFilters
}

const acquisitionRefresher = (
  dataSourceID: string,
  setAcquisitions: React.Dispatch<React.SetStateAction<Status[] | Error | undefined>>,
) => async (abortSignal?: AbortSignal) => {
  try {
    setAcquisitions(await getDataSourceStatus(dataSourceID, 50, abortSignal));
  } catch (e) {
    setAcquisitions(e);
  }
};

const actionFilterOptions = new Map<string, boolean>()

export default (
  { dataSourceID, isAdmin }:
    { dataSourceID: string,
      isAdmin: boolean,
     },
) => {
  const [dataSource, setDataSources] = useState<DataSource | Error>();
  const [filters, setFilters] = useState<Filters>({
    all: true, success: false, fail: false, actions: actionFilterOptions,
  });
  const [acquisitions, setAcquisitions] = useState<Status[] | Error>();
  const [filteredAcquisitions, setFilteredAcquisitions] = useState<Status[] | Error>();
  const refresh = useCallback(
    acquisitionRefresher(dataSourceID, setAcquisitions),
    [setAcquisitions],
  );


  /**
   * Function Telling react when updateDataSource function gets executed, it's fiddling with this state setDataSource (line 245)
   * setDataSource will change datasource line 246
   * @param
   */
  const updateDataSource = async () => {
      getDataSource(dataSourceID).then(setDataSources).catch(setDataSources);
    }

  /**
   * Function When this state changes do this thing, only runs when compoonent loads in
   * Line 261 is where you set a subscriber, if you subscribe it to datasource, whenever you change datasource, - it will go in an infinite loop
   * @param
   */
  useEffect(() => {
    updateDataSource();
    refresh();
  }, []);

  useEffect(() => {
    // search through all acquisitions and put all available actions into a set.
    if (acquisitions instanceof Array) {
      const lastActionOfAcquisition = new Set<string>();
      acquisitions.map((entry) => {
        if (entry.updates) {
          if (entry.updates.length === 0) {
            lastActionOfAcquisition.add('requested')
          } else if (entry.updates.length > 0) {
            lastActionOfAcquisition.add(entry.updates[entry.updates.length - 1].action)
          }
        }
        return true // line to satisfy linter.
      });

      if (lastActionOfAcquisition.size > 0) {
        // generate dictionary to put into Filter.action property.
        lastActionOfAcquisition.forEach((entry) => {
          if (!actionFilterOptions.has(entry)) {
            actionFilterOptions.set(entry, false)
          }
        })

        const newFilters: Filters = { ...filters };
        newFilters.actions = actionFilterOptions
        setFilters(newFilters)
      }
    }
  }, [acquisitions]);

  useEffect(() => {
    if (acquisitions instanceof Array) {
      setFilteredAcquisitions(acquisitions.filter((entry) => filterAcquisitions(filters, entry)));
    }
  }, [filters, acquisitions])

  if (!filteredAcquisitions) {
    return <p className="govuk-caption-m">Loading…</p>;
  }

  if (filteredAcquisitions instanceof Error) {
    return <InternalError errorMessage={filteredAcquisitions.message} />
  }

  if (!dataSource) { // if data source doesnt exist show loading
    return <p className="govuk-caption-m">Loading…</p>;
  }
  if (dataSource instanceof Error) { // maybe handle this error, saying if it's a 404 you know its disabled so you display the "its disabled press this to enable thing"
    return <InternalError errorMessage={dataSource.message} />
  }

  const isEnabled = dataSource.enabled;

  /**
   * Function enables a given datasets status, called from buttons onclick methods
   * @param event
   * @param dataSourceID
   */
  const changeDataSet = async (
    event: MouseEvent<HTMLButtonElement>, dataSource_ID: string, enabled: boolean,
  ) => {
    const button = event.currentTarget;
    button.disabled = true;
    if (enabled) {
          button.textContent = 'Enabling…';
    } else {
          button.textContent = 'Disabling…';
    }
    try {
      await patchDataSet(dataSource_ID, enabled);
    } catch (err) {
      // setDataSource(err);
    }
    updateDataSource();
  };

  return (
    <>
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-two-thirds-from-desktop">
          <span className="govuk-caption-xl">{dataSource ?.name || 'Not Found'}</span>
          <br />
          <h1 className="govuk-heading-xl">{dataSourceID}</h1>
        </div>
        <div className="govuk-grid-column-one-third">
          <Schedule cron={dataSource.schedule} />
          <br />
          {
            metricsControlCheck(dataSource.metrics) ? <Tag className="govuk-tag--grey">Control: Auto Creation</Tag> : <Tag className="govuk-tag--grey">Control: Manual Creation</Tag>
          }
        </div>
      </div>
      <Tabs
        items={[
          {
            id: 'details',
            label: 'Details',
            panel: {
              children: (
                <>
                  <Details open summaryChildren="Documentation">
                    <ReactMarkdown skipHtml className="govuk-body">{dataSource.doc}</ReactMarkdown>
                  </Details>
                  <Details open summaryChildren="Dataset Status">
                    Data Source is
                    {isEnabled ? ' Enabled ' : ' Disabled '}
                    <br />
                    {!isEnabled && (
                    <Button
                      disabled={isEnabled}
                      onClick={(event: MouseEvent<HTMLButtonElement>) => (
                        changeDataSet(event, dataSourceID, true)
                      )}
                    >
                      Enable
                    </Button>
                    )}
                    {isEnabled && (
                      <Button
                        disabled={!isEnabled}
                        onClick={(event: MouseEvent<HTMLButtonElement>) => (
                          changeDataSet(event, dataSourceID, false)
                        )}
                      >
                        Disable
                      </Button>
                    )}
                  </Details>
                  {(dataSource.metrics)
                    && <MetricsTable id={dataSourceID} metrics={dataSource.metrics} />}
                </>
              ),
            },
          },
          {
            id: 'acquisitions',
            label: 'Acquisitions',
            panel: {
              children: (
                <>
                  <Details summaryChildren="Filter">
                    <Checkboxes
                      className="govuk-checkboxes--small"
                      items={filtersToCheckboxes(filters)}
                      name="filters"
                      onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                        setFilters(filtersUpdate(filters, e.currentTarget.value));
                      }}
                    />
                  </Details>
                  <Acquisitions isAdmin={isAdmin} dataSourceID={dataSourceID} allowCreate={dataSource.type === 'commission'} filteredAcquisitions={filteredAcquisitions} refresh={refresh} metrics={dataSource.metrics} />
                </>
              ),
            },
          },
        ] as TabItem[]}
      />
    </>
  );
};
